import React from 'react'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
// const BranchOrders = React.lazy(() => import('./views/pages/branch-orders/BranchOrders'))

// Product Managemnt
const Category = React.lazy(() => import('./views/product-mgmt/category/Category'))
const Product = React.lazy(() => import('./views/product-mgmt/product/product'))

const AddCategory = React.lazy(() =>
  import('./views/product-mgmt/category/add-category/add-category'),
)
const EditCategory = React.lazy(() =>
  import('./views/product-mgmt/category/edit-category/edit-category'),
)
const SubCategory = React.lazy(() => import('./views/product-mgmt/sub-category/sub-category'))
const AddSubCategory = React.lazy(() =>
  import('./views/product-mgmt/sub-category/add-sub-category/add-sub-category'),
)
const EditSubCategory = React.lazy(() =>
  import('./views/product-mgmt/sub-category/edit-sub-category/edit-sub-category'),
)
const AddProduct = React.lazy(() => import('./views/product-mgmt/product/add-product/add-product'))

const Orders = React.lazy(() => import('./views/order-management/orders'))
const EditOrder = React.lazy(() => import('./views/order-management/edit-order/edit-order'))

const Regections = React.lazy(() => import('./views/order-management/rejection-reasons/rejections'))
const AddEditRejections = React.lazy(() =>
  import('./views/order-management/rejection-reasons/add-edit-rejection/addEditRejection'),
)

const Banner = React.lazy(() => import('./views/banners/banners'))
const AddBanner = React.lazy(() => import('./views/banners/add-banner/add-banner'))

const Coupon = React.lazy(() => import('./views/coupons/coupon'))
const AddCoupon = React.lazy(() => import('./views/coupons/add-coupon/add-coupon'))

const PushNotification = React.lazy(() => import('./views/push-notifications/push-notifications'))
const AddPushNotification = React.lazy(() =>
  import('./views/push-notifications/add-push-notification/add-push-notification'),
)
const Customers = React.lazy(() => import('./views/customer-management/customers'))
const Employees = React.lazy(() => import('./views/employee-mgmt/employees'))
const AddEmployee = React.lazy(() => import('./views/employee-mgmt/add-employee/add-employee'))

const EmployeeRole = React.lazy(() => import('./views/employee-mgmt/employee-role/employee-role'))
const AddEmployeeRole = React.lazy(() =>
  import('./views/employee-mgmt/employee-role/employee-role-add/employee-role-add'),
)

//loyalty points
const ConversionRates = React.lazy(() => import('./views/loyalty-points/ConversionRate'))
const AddRate = React.lazy(() => import('./views/loyalty-points/add-rate/AddRate'))

//loyalty point history
const LoyaltyPointHistory = React.lazy(() =>
  import('./views/loyalty-points-history/LoyaltyPointHistory'),
)

//wallet Transaction History
const walletTransactionHistory = React.lazy(() => import('./views/wallet/walletTransactionHistory'))
//wallet
const wallets = React.lazy(() => import('./views/wallet/wallets'))
const AddFunds = React.lazy(() => import('./views/wallet/add-funds/add-funds'))

const Branches = React.lazy(() => import('./views/branches/branches'))
const AddBranch = React.lazy(() => import('./views/branches/add-branch/add-branch'))

// Icons
const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'))
const Flags = React.lazy(() => import('./views/icons/flags/Flags'))
const Brands = React.lazy(() => import('./views/icons/brands/Brands'))

// Notifications
const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'))
const Modals = React.lazy(() => import('./views/notifications/modals/Modals'))
const Toasts = React.lazy(() => import('./views/notifications/toasts/Toasts'))

const Widgets = React.lazy(() => import('./views/widgets/Widgets'))

const DeliveryCharges = React.lazy(() => import('./views/delivery-charges/delivery-charges'))
const AddEditDeliveryCharge = React.lazy(() =>
  import('./views/delivery-charges/add-edit-delivery-charge/add-edit-delivery-charges'),
)

const BranchOrders = React.lazy(() => import('./views/pages/branch-orders/BranchOrders'))
const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/delivery-charge', name: 'Delivery', element: DeliveryCharges },
  { path: '/delivery-charge/add', name: 'Add Delivery', element: AddEditDeliveryCharge },
  {
    path: '/delivery-charge/:deliveryChargeId/edit',
    name: 'Edit Delivery',
    element: AddEditDeliveryCharge,
  },
  { path: '/category', name: 'Category', element: Category },
  { path: '/category/add', name: 'Add', element: AddCategory },
  { path: '/category/:categorytId/edit', name: 'Edit', element: EditCategory },
  { path: '/subcategory', name: 'Sub-category', element: SubCategory },
  { path: '/subcategory/add', name: 'Add', element: AddSubCategory },
  { path: '/subcategory/:subCategoryId/edit', name: 'Edit', element: EditSubCategory },
  { path: '/product', name: 'Product', element: Product },
  { path: '/product/add', name: 'Add', element: AddProduct },
  { path: '/product/:productId/edit', name: 'Edit', element: AddProduct },
  { path: '/product/:productId/edit-y', name: 'Edit', element: AddProduct },
  { path: '/order', name: 'Orders', element: Orders },
  { path: '/order/:orderId/edit', name: 'Edit', element: EditOrder },
  { path: '/rejection', name: 'rejections', element: Regections },
  { path: '/rejection/add', name: 'Add', element: AddEditRejections },
  { path: '/rejection/:rejectionId/edit', name: 'Edit', element: AddEditRejections },
  { path: '/banner', name: 'Banner', element: Banner },
  { path: '/banner/:bannerId/edit', name: 'Edit', element: AddBanner },
  { path: '/banner/add', name: 'Add', element: AddBanner },
  { path: '/coupon', name: 'Coupon', element: Coupon },
  { path: '/coupon/add', name: 'Add', element: AddCoupon },
  { path: '/coupon/:couponId/edit', name: 'Edit', element: AddCoupon },
  { path: '/notification', name: 'PushNotification', element: PushNotification },
  { path: '/notification/add', name: 'AddPushNotification', element: AddPushNotification },
  { path: '/customer', name: 'Customers', element: Customers },
  { path: '/employee', name: 'Employees', element: Employees },
  { path: '/employee/add', name: 'AddEmployee', element: AddEmployee },
  { path: '/employee/:employeeId/edit', name: 'EditEmployee', element: AddEmployee },
  { path: '/employee/roles', name: 'EmployeeRole', element: EmployeeRole },
  { path: '/employee/roles/add', name: 'AddEmployeeRole', element: AddEmployeeRole },
  { path: '/employee/roles/:roleId/edit', name: 'AddEmployeeRole', element: AddEmployeeRole },
  { path: '/branch', name: 'Branches', element: Branches },
  { path: '/branch/add', name: 'AddBranch', element: AddBranch },
  { path: '/branch/:branchId/edit', name: 'EditBranch', element: AddBranch },

  { path: '/icons', exact: true, name: 'Icons', element: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', element: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', element: Flags },
  { path: '/icons/brands', name: 'Brands', element: Brands },
  { path: '/notifications', name: 'Notifications', element: Alerts, exact: true },
  { path: '/notifications/alerts', name: 'Alerts', element: Alerts },
  { path: '/notifications/modals', name: 'Modals', element: Modals },
  { path: '/notifications/toasts', name: 'Toasts', element: Toasts },
  { path: '/widgets', name: 'Widgets', element: Widgets },
  { path: '/branch-orders', name: 'Branch Orders', element: BranchOrders },
  { path: '/conversionRate', name: 'Conversion Rate', element: ConversionRates },
  { path: '/conversionRate/add', name: 'Add', element: AddRate },
  { path: '/conversionRate/:rateId/edit', name: 'Edit', element: AddRate },
  { path: '/loyaltyPointHistory', name: 'Loyalty Point History', element: LoyaltyPointHistory },
  {
    path: '/walletTransactionHistory',
    name: 'Wallet Transaction History',
    element: walletTransactionHistory,
  },
  { path: '/wallets', name: 'Wallets', element: wallets },
  { path: '/wallets/add', name: 'Add', element: AddFunds },
]

export default routes
