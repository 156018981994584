export const isLoggedIn = () => {
  return !!localStorage.getItem('accessToken')
}

export const getToken = () => {
  return localStorage.getItem('accessToken')
}

// export const getRefreshToken = () => {
//   return localStorage.getItem('refreshToken')
// }

export const storeLoginResponse = (loginResponse, tokenExpiry) => {
  localStorage.setItem('accessToken', loginResponse)
  localStorage.setItem('tokenExpiry', tokenExpiry)
  // localStorage.setItem('refreshToken', loginResponse.refreshToken)
}

export const clearToken = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('tokenExpiry')
  localStorage.removeItem('userInfo')
  localStorage.removeItem('components')
}

export const storeToken = (token, tokenExpiry) => {
  localStorage.setItem('accessToken', token) //tokenExpiry
}
export const storeComponenets = (components) => {
  localStorage.setItem('components', JSON.stringify(components))
}

export const storeUserInformation = (customerInfo) => {
  localStorage.setItem('userInfo', JSON.stringify(customerInfo))
}

export const getComponenets = () => {
  return JSON.parse(localStorage.getItem('components'))
}
// export const getExpiryDate = () => {
//   return localStorage.getItem('tokenExpiry')
// }
export const getUserInformation = () => {
  return JSON.parse(localStorage.getItem('userInfo'))
}

// export const goToLogin = () => {
//   clearToken()
//   window.location.href = '#/login'
// }

export function convertUTCtoLocal(utcDate) {
  // Step 1: Create a new Date object with the UTC date and time
  const utcDateTime = new Date(utcDate)

  // Step 2: Get the UTC timestamp in milliseconds
  const utcTimestamp = utcDateTime.getTime()

  // Step 3: Get the local timezone offset in minutes
  const timezoneOffsetInMinutes = utcDateTime.getTimezoneOffset()

  // Step 4: Calculate the local timestamp by adding the timezone offset
  const localTimestamp = utcTimestamp - timezoneOffsetInMinutes * 60 * 1000

  // Step 5: Create a new Date object using the local timestamp
  const localDateTime = new Date(localTimestamp)

  return localDateTime
}

export const convertDate = (date, displayTime) => {
  // const inputDate = new Date(date)
  const localDate = convertUTCtoLocal(date)
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const month = monthNames[localDate.getMonth()]
  const day = localDate.getDate()
  const year = localDate.getFullYear()
  const hours = localDate.getHours()
  const minutes = localDate.getMinutes()
  let amOrPm = hours >= 12 ? 'PM' : 'AM'
  let displayHours = hours % 12 === 0 ? 12 : hours % 12

  if (displayTime) {
    return `${month} ${day}, ${year}, ${displayHours}:${
      minutes < 10 ? '0' : ''
    }${minutes} ${amOrPm}`
  }
  return `${month} ${day}, ${year}`
}

export function getPastDayTimestamps() {
  var currentDate = new Date() // Get current date and time
  var pastDate = new Date() // Create a new date object for past day
  pastDate.setDate(currentDate.getDate() - 30) // Subtract 1 day from current date

  var startDate = pastDate.toISOString() // Convert past date to ISO string
  var endDate = currentDate.toISOString() // Convert current date to ISO string

  return {
    start: startDate,
    end: endDate,
  }
}

export function getBeginningOfDayTimestring() {
  // Step 1: Get the current date in UTC
  const today = new Date()

  // Step 2: Set the time to midnight (00:00:00) for the current date
  today.setUTCHours(0, 0, 0, 0)

  // Step 3: Format the timestring
  const timestring = today.toISOString()

  return timestring
}

export const formatDateTime = (date) => {
  return date.toISOString()
}

// export const isTokenExpired = (expiryDate) => {
//   if (expiryDate) {
//     const expirationDate = new Date(expiryDate)
//     const currentDate = new Date()
//     // return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
//     // console.log(currentDate, expirationDate)
//     return currentDate > expirationDate
//   } else {
//     return true
//   }
// }

export function extractTimeAndTimezone(dateString) {
  const inputDate = dateString

  if (isNaN(inputDate)) {
    return 'Invalid date'
  }

  const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false }
  const timeString = inputDate.toLocaleTimeString('en-US', timeOptions)

  const timezoneOffset = inputDate.getTimezoneOffset()
  const offsetHours = Math.floor(Math.abs(timezoneOffset) / 60)
  const offsetMinutes = Math.abs(timezoneOffset) % 60
  const offsetSign = timezoneOffset < 0 ? '+' : '-'

  const offsetString = `${offsetSign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes
    .toString()
    .padStart(2, '0')}`

  return `${timeString} GMT${offsetString}`
}
