import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CNavLink,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'

import logoCircleWhite from '../../../assets/images/logo-circle-white.png'
import logoDark from '../../../assets/brand/stories-logo-dark.png'
import logoWhite from '../../../assets/images/stories-logo-light.png'

import { useMutation } from 'react-query'
import { login } from 'src/api/auth'
import { message } from 'antd'
import { storeComponenets, storeLoginResponse, storeUserInformation } from 'src/utils/main-utils'
import CustomButton from 'src/components/custom_button/custom_button'

const Login = () => {
  const navigate = useNavigate()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [messageApi, contextHolder] = message.useMessage()

  const [isSmallScreen, setIsSmallScreen] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 576px)')
    setIsSmallScreen(mediaQuery.matches)

    const handleMediaQueryChange = (event) => {
      setIsSmallScreen(event.matches)
    }

    mediaQuery.addListener(handleMediaQueryChange)

    // Cleanup function to remove the listener when the component unmounts
    return () => {
      mediaQuery.removeListener(handleMediaQueryChange)
    }
  }, [])

  const handleLogin = () => {
    mutation.mutate({ email: username, password: password, clientId: 1 })
  }

  const mutation = useMutation(login, {
    onError: (error, variables, context) => {
      // An error happened!
      messageApi.open({
        type: 'error',
        content: 'Invalid Username or Password',
        duration: 5,
        className: '',
      })
    },
    onSuccess: (data) => {
      messageApi.open({
        type: 'success',
        content: 'Login Successfull',
        duration: 5,
        className: '',
      })
      // console.log(data?.data?.token)
      storeLoginResponse(data?.data?.token, data?.data?.tokenExpiry)
      storeComponenets(data?.data?.components)
      storeUserInformation(data?.data?.employeeInformation)
      navigate('/dashboard')
    },
  })

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      {contextHolder}
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              {isSmallScreen ? (
                <div className="text-white py-4" style={{ width: '100%' }}>
                  <img alt="logo" width={'100%'} src={logoDark}></img>
                </div>
              ) : (
                <></>
              )}
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        id="txtUsername"
                        placeholder="Username"
                        autoComplete="username"
                        value={username}
                        onChange={(e) => {
                          setUsername(e.target.value)
                        }}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value)
                        }}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={4}>
                        <CustomButton
                          color="primary"
                          // className="px-4"
                          onClick={() => handleLogin()}
                          loading={mutation.isLoading}
                        >
                          Login
                        </CustomButton>
                      </CCol>
                      <CCol xs={2}></CCol>
                      <CCol xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                        <CNavLink
                          // href=""
                          active={false}
                          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                          onClick={() => {
                            navigate('/send-email')
                          }}
                        >
                          Reset Password
                        </CNavLink>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              {!isSmallScreen ? (
                <CCard className="text-white bg-primary py-5" style={{ width: '100%' }}>
                  <CCardBody className="text-center">
                    <div>
                      <img alt="logo" src={logoCircleWhite}></img>
                    </div>
                    <div className="mt-4">
                      <img alt="logo" width={'60%'} src={logoWhite}></img>
                    </div>
                  </CCardBody>
                </CCard>
              ) : (
                <></>
              )}
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
