import { CButton, CSpinner } from '@coreui/react'
import React from 'react'
import PropTypes from 'prop-types'

const CustomButton = (props) => {
  const { value, loading, disabled, type, component, color, className, onClick, children, style } =
    props

  return (
    <CButton
      className={className}
      component={component}
      type={type}
      color={color}
      disabled={loading ? loading : disabled}
      value={value}
      style={style}
      // value={loadingbutton ? `${(<CSpinner color="success" />)} Save` : 'SAVE'}
      onClick={onClick}
    >
      {loading ? (
        <>
          <CSpinner color={'success'} size="sm" aria-hidden="true" /> {children}
        </>
      ) : (
        <> {children}</>
      )}
    </CButton>
  )
}

CustomButton.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  component: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.any,
}

export default CustomButton
