import axios from 'axios'
import { clearToken, getToken } from '../utils/main-utils'
const { REACT_APP_BASE_URL: BASE_URL } = process.env

const api = axios.create({
  baseURL: BASE_URL,
})

api.interceptors.request.use(function (config) {
  const token = getToken()
  if (token) {
    config.headers.Authorization = `Employee ${token}`
  }
  // if (isTokenExpired(getExpiryDate())) {
  //   clearToken()
  //   window.location = '/login'
  //   return null
  // }
  return config
})

const goToLogin = () => {
  clearToken()
  window.location.href = window.location.origin + '/#/login'
}

api.interceptors.response.use(
  function (response) {
    // const token = response.headers['token_expired']
    // if (token) {
    //   storeToken(token)
    // }
    return response
  },
  function (error) {
    if (error.response?.status === 401) {
      goToLogin()
    }
    return Promise.reject(error)
  },
)

export default api
