import React from 'react'
import { CAvatar, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'
import { cilAccountLogout } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import imgAccount from './../../assets/images/avatars/account.png'
import { clearToken } from 'src/utils/main-utils'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

const AppHeaderDropdown = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar src={imgAccount} size="md" className="img-account" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem
          onClick={() => {
            clearToken()
            queryClient.clear()
            navigate('login')
          }}
        >
          <CIcon icon={cilAccountLogout} className="me-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
