import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { clearToken, isLoggedIn } from './main-utils'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { getPermissions } from 'src/api/permissions'
import { CSpinner } from '@coreui/react'

const PrivateRoute = ({ children }) => {
  const location = useLocation()

  const { data, isLoading } = useQuery('user-permissions', getPermissions, {
    refetchOnWindowFocus: false,
    enabled: isLoggedIn(),
  })

  if (isLoading) {
    return <CSpinner color="primary" />
  }
  if (!isLoggedIn()) {
    clearToken()
    return <Navigate to={'/login'} />
  } else {
    const components = data?.data
    // Loop through each object in the array
    for (let i = 0; i < components?.length; i++) {
      // Check if the stringToCheck matches the key of the current object
      if (location?.pathname.includes(components[i].key)) {
        if (location?.pathname.includes('add')) {
          if (components[i].actions.includes('Add')) return children
          else return <Navigate to={`/${data?.data[0].key}`} />
        } else if (location?.pathname.includes('edit')) {
          if (components[i].actions.includes('Update')) return children
          else return <Navigate to={`/${data?.data[0].key}`} />
        }
        // If there is a match, return true
        return children
      }
    }
  }

  return <Navigate to={`/${data?.data[0].key}`} />
}
PrivateRoute.propTypes = {
  children: PropTypes.any,
}
export default PrivateRoute
