import React, { Suspense } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react'

// routes config
import routes from '../routes'
import PrivateRoute from 'src/utils/PrivateRoute'
import { useQuery } from 'react-query'
import { getPermissions } from 'src/api/permissions'
import { isLoggedIn } from 'src/utils/main-utils'

const AppContent = () => {
  const { data } = useQuery('user-permissions', getPermissions, {
    refetchOnWindowFocus: false,
    enabled: isLoggedIn(),
  })

  return (
    <CContainer lg>
      <Routes>
        <Route
          element={
            <PrivateRoute>
              <Suspense fallback={<CSpinner color="primary" />}>
                <Outlet />
              </Suspense>
            </PrivateRoute>
          }
        >
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            )
          })}
        </Route>
        <Route path="*" element={<Navigate to={`/${data?.data[0].key}`} replace />} />
      </Routes>
    </CContainer>
  )
}

export default React.memo(AppContent)
