export const logoNegative = [
  '608 134',
  `
  <title>coreui react pro logo</title>
  <g clip-path="url(#clip0_618_1815)">
<path d="M51.1607 70.1885C51.1607 65.4544 44.4398 64.391 33.2956 61.029C19.2367 56.9124 1.54305 50.463 1.54305 30.909C1.54305 11.3549 14.5047 0.0341797 40.6336 0.0341797C57.7444 0.0341797 69.9516 3.70485 79.1413 8.43898L74.4093 33.0359C63.5737 27.6843 50.7492 24.3223 40.8051 24.3223C34.8386 24.3223 31.6497 25.5573 31.6497 29.3652C31.6497 33.7906 40.8051 36.0891 50.2691 38.5247C65.391 42.6414 81.2673 49.5367 81.2673 67.7186C81.2673 86.8266 66.8998 99.9656 39.262 99.9656C23.3858 99.9656 8.70966 96.1577 0 91.4235L4.59486 66.8266C14.9847 71.8695 27.5006 75.6774 39.7421 75.6774C46.6001 75.6774 51.195 74.3052 51.195 70.1885H51.1607Z" fill="white"/>
<path d="M280.972 97.7013V2.19531H324.212C350.958 2.19531 364.845 14.408 364.845 33.5161C364.845 45.7288 359.05 55.9861 346.226 61.0289L369.886 97.7013H336.591L319.48 68.679L311.079 69.4337V97.7013H280.972ZM333.676 35.643C333.676 28.4732 329.698 25.1113 318.691 25.1113H310.427V48.0272L320.817 47.89C329.973 46.8265 333.642 43.4646 333.642 35.6773L333.676 35.643Z" fill="white"/>
<path d="M380.996 2.19531H411.103V97.7013H380.996V2.19531Z" fill="white"/>
<path d="M428.968 2.19531H503.514V25.5915H458.286V38.113H494.016V61.5092H458.286V74.3394H503.514V97.7356H428.968V2.19531Z" fill="white"/>
<path d="M564.928 70.1885C564.928 65.4544 558.207 64.391 547.063 61.029C533.004 56.9124 515.276 50.4973 515.276 30.909C515.276 11.3206 528.272 0.0341797 554.367 0.0341797C571.477 0.0341797 583.684 3.70485 592.874 8.43898L588.142 33.0359C577.307 27.6843 564.482 24.3223 554.538 24.3223C548.572 24.3223 545.383 25.5573 545.383 29.3652C545.383 33.7906 554.538 36.0891 564.002 38.5247C579.124 42.6414 595 49.5367 595 67.7186C595 86.8266 580.633 99.9656 552.995 99.9656C537.119 99.9656 522.443 96.1577 513.733 91.4235L518.328 66.8266C528.718 71.8695 541.233 75.6774 553.475 75.6774C560.333 75.6774 564.928 74.3052 564.928 70.1885Z" fill="white"/>
<path d="M174.742 2.19531H87.0625V26.7922H115.935V97.7013H146.041V26.7922H157.254C161.026 17.221 167.095 8.81624 174.742 2.19531Z" fill="white"/>
<path d="M231.732 77.6329C231.218 71.801 229.743 66.1064 227.549 60.7204C225.423 55.3002 222.199 50.3945 218.77 45.7633L213.078 38.9022C212.049 37.7015 211.192 36.3979 210.266 35.1286C209.443 33.7907 208.518 32.4871 207.832 31.0463C204.78 25.4545 203.031 18.9365 203.168 12.3842C203.237 8.57633 203.923 4.80274 205.054 1.20068C203.614 1.50943 202.174 1.88679 200.802 2.33276C200.802 2.40137 200.734 2.43568 200.734 2.50429C198.162 7.82161 196.653 13.6192 196.688 19.4168C196.756 25.2144 198.402 30.8748 201.282 35.8147C204.026 40.9262 208.175 44.8027 212.187 49.6398C215.924 54.4768 219.49 59.6226 221.891 65.4202C224.394 71.1492 226.108 77.2556 226.794 83.5678C227.309 88.6449 227.171 93.7907 226.383 98.8336C227.446 98.5935 228.509 98.3533 229.572 98.0446C231.595 91.4237 232.349 84.4597 231.766 77.6672L231.732 77.6329Z" fill="white"/>
<path d="M220.142 84.0482C219.902 78.4221 218.702 72.8647 216.747 67.5131C214.895 62.0928 211.878 57.1185 208.655 52.3501C205.328 47.7875 200.939 43.0534 198.368 37.393C195.59 31.8355 194.184 25.5576 194.493 19.3827C194.801 13.5508 196.619 7.92472 199.293 2.88184C179.954 9.7772 166.066 28.2335 166.066 49.9487C166.066 77.5302 188.423 99.8973 215.993 99.8973C216.781 99.8973 217.57 99.8973 218.359 99.8287C219.73 94.7172 220.416 89.3655 220.142 84.0139V84.0482Z" fill="white"/>
<path d="M265.919 49.9485C265.919 22.3671 243.562 0 215.993 0C215.17 0 214.347 0 213.524 0.0686106C211.535 4.04803 210.198 8.33619 209.82 12.693C209.409 18.0446 210.438 23.5334 212.804 28.6106C214.998 33.7907 218.77 38.0103 222.371 43.1904C225.697 48.3362 228.783 53.7221 230.669 59.5883C232.658 65.3859 233.823 71.458 233.961 77.5643C234.098 84.4597 232.966 91.3551 230.634 97.7358C251.037 91.458 265.885 72.4528 265.885 49.9828L265.919 49.9485Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_618_1815">
<rect width="595" height="100" fill="white"/>
</clipPath>
</defs>`,
]
