import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import routes from '../routes'

import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react'

const AppBreadcrumb = () => {
  const currentLocation = useLocation().pathname
  const navigate = useNavigate()

  function checkFirstAndLastWords(string1, string2) {
    // Function to extract the first and last words from a string
    function extractWords(string) {
      const words = string.split('/').filter((word) => word.trim() !== '')
      if (words.length > 0) {
        const firstWord = words[0]
        const lastWord = words[words.length - 1]
        return [firstWord, lastWord]
      } else {
        return [null, null]
      }
    }

    // Extract the first and last words from both strings
    const [firstWord1, lastWord1] = extractWords(string1)
    const [firstWord2, lastWord2] = extractWords(string2)

    // Check if the first and last words are the same
    const isFirstWordSame = firstWord1 === firstWord2
    const isLastWordSame = lastWord1 === lastWord2

    // Return true if both the first and last words are the same, otherwise false
    return isFirstWordSame && isLastWordSame
  }

  const getRouteName = (pathname, routes) => {
    const currentRoute = routes.find(
      (route) => route.path === pathname || checkFirstAndLastWords(route.path, pathname),
    )
    return currentRoute ? currentRoute.name : false
  }

  const getBreadcrumbs = (location) => {
    const breadcrumbs = []

    location.split('/').reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`
      const routeName = getRouteName(currentPathname, routes)
      routeName &&
        breadcrumbs.push({
          pathname: currentPathname,
          name: routeName,
          active: index + 1 === array.length ? true : false,
        })
      return currentPathname
    })
    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(currentLocation)

  return (
    <CBreadcrumb className="m-0 ms-2">
      <CBreadcrumbItem href="/">Home</CBreadcrumbItem>
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <CBreadcrumbItem
            {...(breadcrumb.active ? { active: true } : { href: breadcrumb?.pathname })}
            key={index}
            onClick={(e) => {
              e.preventDefault()
              // console.log(breadcrumb?.pathname)
              navigate(breadcrumb?.pathname)
            }}
          >
            {breadcrumb.name}
          </CBreadcrumbItem>
        )
      })}
    </CBreadcrumb>
  )
}

export default React.memo(AppBreadcrumb)
