import React, { useEffect } from 'react'

const RedirectStore = () => {
  const externalLink =
    'https://storieslbstorageprodv2.blob.core.windows.net/prod/stories-download.html'

  useEffect(() => {
    // Redirect to the external link on component load
    window.location.href = externalLink
  }, []) // The empty dependency array makes this effect run only once on component mount

  return <div>Redirecting...</div>
}

export default RedirectStore
