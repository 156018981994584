import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CInputGroup,
  CRow,
} from '@coreui/react'
import { LockOutlined } from '@ant-design/icons'

import logoCircleWhite from '../../../assets/images/logo-circle-white.png'
import logoWhite from '../../../assets/images/stories-logo-light.png'
import { useMutation } from 'react-query'
import { Form, Input, message } from 'antd'
import CustomButton from 'src/components/custom_button/custom_button'
import { resetPassword } from 'src/api/resetPassword'
import { useLocation, useNavigate } from 'react-router-dom'

const ResetPassword = () => {
  //   const navigate = useNavigate()
  const [form] = Form.useForm()
  const location = useLocation()
  const navigate = useNavigate()

  const [messageApi, contextHolder] = message.useMessage()

  const [payload, setPayload] = useState({
    token: '',
    newPassword: '',
  })

  const handleLogin = () => {
    mutation.mutate(payload)
  }

  const mutation = useMutation(resetPassword, {
    onError: (error, variables, context) => {
      // An error happened!
      messageApi.open({
        type: 'error',
        content: error?.response?.data?.ErrorMessage ?? 'Failed to send Code',
        duration: 5,
        className: '',
      })
    },
    onSuccess: () => {
      messageApi.open({
        type: 'success',
        content: 'Password Updated!',
        duration: 5,
        className: '',
      })
      navigate('/login')
    },
  })
  function getTokenValue(inputString) {
    const separatorIndex = inputString.indexOf('=')
    if (separatorIndex === -1 || separatorIndex === 0) {
      // The input string is not in the expected format
      return null
    }
    return inputString.slice(separatorIndex + 1)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search).toString()
    const Token = getTokenValue(decodeURIComponent(searchParams))
    Token
      ? setPayload((prevPayload) => {
          return {
            ...prevPayload,
            token: Token,
          }
        })
      : navigate('/login')
  }, [location.search, navigate])

  const validatePassword = (rule, value) => {
    // Password validation rules
    // At least 8 characters, one uppercase letter, one lowercase letter, and one number
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>?/]{8,}$/

    if (value && !passwordRegex.test(value)) {
      return Promise.reject(
        'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number',
      )
    }

    return Promise.resolve()
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      {contextHolder}
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <Form onFinish={handleLogin} form={form}>
                    <h1>Reset Pasword</h1>
                    <CFormLabel htmlFor="txtPrice">New Password</CFormLabel>
                    <CInputGroup className="">
                      <Form.Item
                        style={{
                          width: '100%',
                        }}
                        name={'password'}
                        rules={[
                          { required: true, message: 'Please enter a password' },
                          { validator: validatePassword },
                          // { validator: validatePasswordMatch },
                        ]}
                      >
                        <Input
                          name={'password'}
                          type={'password'}
                          placeholder="Password"
                          disabled={mutation?.isLoading}
                          addonBefore={<LockOutlined />}
                          value={payload?.email}
                          onChange={(e) => {
                            setPayload((prevPayload) => {
                              return {
                                ...prevPayload,
                                email: e.target.value,
                              }
                            })
                          }}
                        />
                      </Form.Item>
                    </CInputGroup>
                    <CFormLabel htmlFor="txtPrice">Confirm Password</CFormLabel>
                    <CInputGroup className="">
                      <Form.Item
                        style={{
                          width: '100%',
                        }}
                        name={'confirmPassword'}
                        dependencies={['password']}
                        rules={[
                          { required: true, message: 'Please enter the reset password' },
                          {
                            validator: (_, value) =>
                              value === document.getElementById('password').value
                                ? Promise.resolve()
                                : Promise.reject('The reset password does not match the password'),
                          },
                        ]}
                      >
                        <Input
                          name={'confirmPassword'}
                          type={'password'}
                          placeholder="Confirm Password"
                          disabled={mutation?.isLoading}
                          addonBefore={<LockOutlined />}
                          value={payload?.email}
                          onChange={(e) => {
                            setPayload((prevPayload) => {
                              return {
                                ...prevPayload,
                                newPassword: e.target.value,
                              }
                            })
                          }}
                        />
                      </Form.Item>
                    </CInputGroup>
                    <CRow>
                      <CCol xs={12}>
                        <CustomButton
                          color="primary"
                          type="submit"
                          className="px-4"
                          //   onClick={() => handleLogin()}
                          loading={mutation.isLoading}
                        >
                          Reset Password
                        </CustomButton>
                      </CCol>
                    </CRow>
                  </Form>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary py-5" style={{ width: '100%' }}>
                <CCardBody className="text-center">
                  <div>
                    <img alt="logo" src={logoCircleWhite}></img>
                  </div>
                  <div className="mt-4">
                    <img alt="logo" width={'60%'} src={logoWhite}></img>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ResetPassword
