import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  SisternodeOutlined,
  FileDoneOutlined,
  TagOutlined,
  TeamOutlined,
  ShoppingOutlined,
  UserSwitchOutlined,
  HomeOutlined,
  FormOutlined,
  CloseCircleOutlined,
  MoneyCollectOutlined,
  BookOutlined,
} from '@ant-design/icons'
import {
  cilBell,
  cilSettings,
  cilSpeedometer,
  cilFastfood,
  cilImage,
  cilWallet,
  cilSmile,
  cilPlus,
  cilMinus,
} from '@coreui/icons'
import { CNavItem, CNavTitle } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },

  {
    component: CNavTitle,
    name: 'PRODUCT MANAGEMENT',
    related: ['category', 'subcategory', 'product'],
  },
  {
    component: CNavItem,
    name: 'Categories',
    to: '/category',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Sub Categories',
    to: '/subcategory',
    icon: <SisternodeOutlined className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Products',
    to: '/product',
    icon: <CIcon icon={cilFastfood} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'ORDER MANAGEMENT',
    related: ['order', 'rejection', 'delivery-charge'],
  },
  {
    component: CNavItem,
    name: 'Orders',
    to: '/order',
    icon: <FileDoneOutlined className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Rejection Reasons',
    to: '/rejection',
    icon: <CloseCircleOutlined className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Delivery Charges',
    to: '/delivery-charge',
    icon: <MoneyCollectOutlined className="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'PROMOTIONS',
    related: ['banner', 'coupon', 'notification'],
  },
  {
    component: CNavItem,
    name: 'Banners',
    to: '/banner',
    icon: <CIcon icon={cilImage} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Coupons',
    to: '/coupon',
    icon: <TagOutlined className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Push Notifications',
    to: '/notification',
    icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'LOYALTYPOINT MANAGEMENT',
    related: ['conversionRate'],
  },
  {
    component: CNavItem,
    name: 'Points Rate',
    to: '/conversionRate',
    icon: <CIcon icon={cilSmile} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Loyalty Point History',
    to: '/loyaltyPointHistory',
    icon: <BookOutlined className="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Wallet MANAGEMENT',
    related: ['walletTransactionHistory'],
  },
  {
    component: CNavItem,
    name: 'Wallets',
    to: '/wallets',
    icon: <CIcon icon={cilWallet} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Recharge Wallet',
    to: '/wallets/add',
    icon: <CIcon icon={cilPlus} customClassName="nav-icon" />,
    state: { transactionType: 1 },
  },
  {
    component: CNavItem,
    name: 'Deduct From Wallet',
    to: '/wallets/add',
    icon: <CIcon icon={cilMinus} customClassName="nav-icon" />,
    state: { transactionType: 2 },
  },
  {
    component: CNavItem,
    name: 'Transaction History',
    to: '/walletTransactionHistory',
    icon: <BookOutlined className="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'CUSTOMER MANAGEMENT',
    related: ['customer'],
  },
  {
    component: CNavItem,
    name: 'Customers',
    to: '/customer',
    icon: <TeamOutlined className="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'EMPLOYEE MANAGEMENT',
    related: ['employee', 'roles'],
  },
  {
    component: CNavItem,
    name: 'Employee Roles',
    to: '/employee/roles',
    icon: <ShoppingOutlined className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Employees',
    to: '/employee',
    icon: <UserSwitchOutlined className="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'BRANCH MANAGEMENT',
    related: ['branch', 'branch-orders'],
  },
  {
    component: CNavItem,
    name: 'Branches',
    to: '/branch',
    icon: <HomeOutlined className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Branch Orders',
    to: '/branch-orders',
    icon: <FormOutlined className="nav-icon" />,
  },
]

export default _nav
