import React, { Component } from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { ConfigProvider } from 'antd'
import ResetPassword from './views/pages/reset-password/ResetPassword'
import SendEmail from './views/pages/send-email/SendEmail'
import PrivateRoute from './utils/PrivateRoute'
import routes from './routes'
import Login from './views/pages/login/Login'
import DefaultLayout from './layout/DefaultLayout'
import Register from './views/pages/register/Register'
import Page404 from './views/pages/page404/Page404'
import Page500 from './views/pages/page500/Page500'
import TermsAndConditions from './views/terms-and-conditions/terms-and-conditions'
import RedirectStore from './views/pages/redirect-store/RedirectStore'
// Containers

// const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages

// const Login = React.lazy(() => import('./views/pages/login/Login'))
// const BranchOrders = React.lazy(() => import('./views/pages/branch-orders/BranchOrders'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  componentDidMount() {
    // Get the current URL
    const currentUrl = window.location.href
    console.log(currentUrl)

    // Substring to check
    const substringToCheck = 'www.storiesapps.com'

    // Check if the URL contains the substring
    if (currentUrl.includes(substringToCheck)) {
      window.location.href = 'https://admin.storiesapps.com/#/login'
      // You can perform further actions here if needed
    }
  }

  render() {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#00704A ',
          },
        }}
      >
        <HashRouter>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route
              exact
              path="/terms-and-conditions"
              name="Terms Page"
              element={<TermsAndConditions />}
            />
            <Route exact path="/download" name="Redirect Store" element={<RedirectStore />} />
            <Route exact path="/reset-password" name="Login Page" element={<ResetPassword />} />
            <Route exact path="/send-email" name="Send Email Page" element={<SendEmail />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route
              element={
                <PrivateRoute>
                  <DefaultLayout />
                </PrivateRoute>
              }
            >
              {routes.map((route, idx) => {
                return (
                  route.element && (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      element={<route.element />}
                    />
                  )
                )
              })}
            </Route>
            <Route path="*" name="Home" element={<Navigate to={'/dashboard'} replace />} />
          </Routes>
        </HashRouter>
      </ConfigProvider>
    )
  }
}

export default App
