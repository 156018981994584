import React, { useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CInputGroup,
  CRow,
} from '@coreui/react'
import { MailOutlined } from '@ant-design/icons'

import logoCircleWhite from '../../../assets/images/logo-circle-white.png'
import logoWhite from '../../../assets/images/stories-logo-light.png'
import { useMutation } from 'react-query'
import { Form, Input, message } from 'antd'
import CustomButton from 'src/components/custom_button/custom_button'
import { getCode } from 'src/api/resetPassword'

const SendEmail = () => {
  //   const navigate = useNavigate()
  const [form] = Form.useForm()

  const [messageApi, contextHolder] = message.useMessage()

  const [payload, setPayload] = useState({
    email: null,
    clientId: 1,
  })

  const handleLogin = () => {
    mutation.mutate(payload)
  }

  const onReset = () => {
    form.resetFields()
  }
  const mutation = useMutation(getCode, {
    onError: (error, variables, context) => {
      // An error happened!
      messageApi.open({
        type: 'error',
        content: error?.response?.data?.ErrorMessage ?? 'Failed to send Code',
        duration: 5,
        className: '',
      })
    },
    onSuccess: () => {
      messageApi.open({
        type: 'success',
        content: 'Code Sent Please Check Your Email',
        duration: 5,
        className: '',
      })

      setPayload({
        email: null,
        clientId: 1,
      })
      onReset()
    },
  })

  const validateEmail = (rule, value) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (value && !emailRegex.test(value)) {
      return Promise.reject('Please enter a valid email address')
    }

    return Promise.resolve()
  }

  //   useEffect(() => {
  //     console.log(payload)
  //   }, [payload])

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      {contextHolder}
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <Form onFinish={handleLogin} form={form}>
                    <h1>Send Email</h1>
                    <CFormLabel htmlFor="txtPrice">Email</CFormLabel>
                    <CInputGroup className="mb-3">
                      <Form.Item
                        style={{
                          width: '100%',
                        }}
                        name={'email'}
                        rules={[
                          { required: true, message: 'Please enter your email address' },
                          { validator: validateEmail },
                        ]}
                      >
                        <Input
                          name={'email'}
                          placeholder="John@Doe.com"
                          disabled={mutation?.isLoading}
                          addonBefore={<MailOutlined />}
                          value={payload?.email}
                          onChange={(e) => {
                            setPayload((prevPayload) => {
                              return {
                                ...prevPayload,
                                email: e.target.value,
                              }
                            })
                          }}
                        />
                      </Form.Item>
                    </CInputGroup>

                    <CRow>
                      <CCol xs={12}>
                        <CustomButton
                          color="primary"
                          type="submit"
                          className="px-4"
                          //   onClick={() => handleLogin()}
                          loading={mutation.isLoading}
                        >
                          Send
                        </CustomButton>
                      </CCol>
                    </CRow>
                  </Form>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary py-5" style={{ width: '100%' }}>
                <CCardBody className="text-center">
                  <div>
                    <img alt="logo" src={logoCircleWhite}></img>
                  </div>
                  <div className="mt-4">
                    <img alt="logo" width={'60%'} src={logoWhite}></img>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default SendEmail
